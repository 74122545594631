html, body {
    height: 100%;
}

body {
    margin: 0;
    font: 13px/20px "Exo 2", Arial, sans-serif;
    min-height: 100vh;
}

* {
    box-sizing: border-box;
}

:root {
    --primary-color: #FFCB06;
    --primary-hover: #cf9b05;
}

#root, .wrapper {
    height: 100%;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

header, footer {
    flex: none;
}

main {
    flex: 1;
}

.container {
    width: 1197px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.text-primary {
    color: var(--primary-color);
}

.btn {
    display: inline-block;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    transition: .3s;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    min-width: 140px;
    user-select: none;
    border: none;
    outline: none
}

.btn--primary {
    background: var(--primary-color);
    color: #000;
}

.btn--primary:hover {
    background: var(--primary-hover);
}